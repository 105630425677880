const transaction_history_tbl_paid = [
    { text: "MODE OF PAYMENT", value: "mode_of_payment", sortable: false},
    { text: "COURSE", value: "title", width: "10%", sortable: false},
    { text: "TRANSACTION DATE", value: "date", align: 'center'},
    { text: "AMOUNT", value: "amount", align: 'center', sortable: false},
    { text: "STATUS", value: "status", align: 'center', sortable: false},
    { text: "NOMINATION FORM", value: "nomination_form", align: 'center', sortable: false},
    { text: "TYPE OF PAYMENT", value: "type_of_payment", align: 'center', sortable: false},
    { text: "PROOF OF PAYMENT", value: "proof_of_payment", align: 'center', sortable: false},
    { text: "RECEIPT", value: "receipt", align: 'center', sortable: false},
    { text: "DATE ENROLLED", value: "date_enrolled", align: 'center', sortable: false},
    { text: "", value: "action", align: 'center', sortable: false },
    { text: "", value: "cancel", align: 'center', sortable: false }
]

const transaction_history_tbl_free = [
    { text: "COURSE", value: "title", width: "30%"},
    { text: "DATE ENROLLED", value: "date", align: 'center'},
]

export  { transaction_history_tbl_paid, transaction_history_tbl_free }